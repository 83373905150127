/* global nelothemes_change, wp */


// Add a file via the wp.media function
function nelothemes_add_file(event, selector) {

	event.preventDefault();

	var frame;
	var jQueryel = jQuery(this);
	


	// If the media frame already exists, reopen it.
	if ( frame ) {
		frame.open();
		return;
	}

	// Create the media frame.
	frame = wp.media({
		multiple: false,
		library: {
			//type: 'image' //Only allow images
		},
		// Set the title of the modal.
		title: jQueryel.data('choose'),

		// Customize the submit button.
		button: {
			// Set the text of the button.
			text: jQueryel.data('update')
			// Tell the button not to close the modal, since we're
			// going to refresh the page when the image is selected.

		}
	});

	// When an image is selected, run a callback.
	frame.on( 'select', function() {

		// Grab the selected attachment.
		var attachment = frame.state().get('selection').first();
		frame.close();

		selector.find('.upload').val(attachment.attributes.url);
                
		var thumbSrc = attachment.attributes.url;

		if ( !selector.find('.upload').hasClass('noPreview') ) {
			selector.find('.screenshot').empty().hide().append('<img class="redux-option-image" src="' + thumbSrc + '">').slideDown('fast');
		}
		//selector.find('.media_upload_button').unbind();
		selector.find('.remove-image').removeClass('hide');//show "Remove" button
		selector.find('.redux-background-properties').slideDown();
	});

	// Finally, open the modal.
	frame.open();
}


// Function to remove the image on click. Still requires a save
function nelothemes_remove_file(selector) {

	// This shouldn't have been run...
	if (!selector.find('.remove-image').addClass('hide')) {
		return;
	}
	selector.find('.remove-image').addClass('hide');//hide "Remove" button
	selector.find('.upload').val('');

	var screenshot = selector.find('.screenshot');
	
	// Hide the screenshot
	screenshot.slideUp();

	selector.find('.remove-file').unbind();
	// We don't display the upload button if .upload-notice is present
	// This means the user doesn't have the WordPress 3.5 Media Library Support
	if ( jQuery('.section-upload .upload-notice').length > 0 ) {
		jQuery('.media_upload_button').remove();
	}

}

(function($){
	"use strict";
    
    $.nelothemes = $.nelothemes || {};
	
    $(document).ready(function () {
         $.nelothemes.media();
    });

	/**
	* Media Uploader
	* Dependencies		: jquery, wp media uploader
	* Feature added by	: Smartik - http://smartik.ws/
	* Date				: 05.28.2013
	*/
    $.nelothemes.media = function(){
		// Remove the image button
                $(document).on('click', '.remove-image, .remove-file', function( event ){
                   nelothemes_remove_file( $(this).parents('fieldset.redux-field:first') );
                });
                
		// Upload media button
                $(document).on('click', '.media_upload_button', function( event ){
                   nelothemes_add_file( event, $(this).parents('fieldset.redux-field:first') );
                });
                
    };

})(jQuery);