/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                var owl = $('#fce-home-carousel');
                owl.owlCarousel({
                    items: 1,
                    autoPlay: true,
                    navigation: true,
                    transitionStyle: "fade",
                    itemsCustom: [[0, 1], [400, 1], [700, 1], [1000, 1], [1200, 1], [1600, 1]],
                    navigationText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>']
                });
                
                //profile fields update 

                $('.btn-update-field').on('click', function (event) {
                    event.preventDefault();
                    var button = $(this);
                    var form = button.closest('.profile-field-form');
                    var loader = form.find('.btn-loader');
                    var inputs = form.find('input');
                    var alert = form.find('.alert');
                    var values = inputs.serializeArray();
                    var target = button.data('target');
                    var hide_form = parseInt(button.data('hide-form'));
                    var validation = form.find("input[name='validation']").val();
                    var current_pass = form.find("input[name='current_pass']").val();
                    if (validation === 'fail') {
                        return false;
                    }

                    var validated_items = [];

                    values = $.grep(values, function (v) {
                        return v.name !== 'validation' && v.name !== 'user_email2' && v.name !== 'user_pass2' && v.name !== 'current_pass';
                    });

                    $.each( values, function( key, field ) {
                        if($.trim(field.value)){
                            validated_items.push(field.value);
                        }
                    });

                    if(values.length === validated_items.length){

                        $.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {
                                values: values,
                                'is_xprofile': button.data('is-xprofile'),
                                'current_pass': current_pass,
                                'action': 'fce_save_profile_field'
                            },
                            beforeSend: function () {
                                loader.show();
                            },
                            success: function (output) {
                                loader.hide();
                                if (parseInt(output.success)) {
                                    alert.removeClass('alert-warning').addClass('alert-info');
                                    var target_html = '';
                                    $.each(values, function (k, v) {
                                        if (v.name === 'user_pass') {
                                            alert.html('Your password has been updated!');
                                        } else {
                                            target_html += '<p>' + v.value + '</p>';
                                        }
                                    });
                                    $(target).html(target_html);
                                    if (hide_form) {
                                        form.slideUp();
                                    }
                                }
                            },
                            error: function () {
                                alert('A system error...Contact the site admin');
                            }
                        });

                    }else {
                        alert.removeClass('alert-info').addClass('alert-warning').html('All fields are required!');
                    }



                });
                
                $('.fce-edit-profile').on('click',function(event){
                    event.preventDefault();
                    var link = $(this);
                    var form   = link.closest('tr').find('.profile-field-form'); 
                    form.slideDown();
                });
                
                $('.btn-cancel-update').on('click',function(event){
                    event.preventDefault();
                    var button = $(this);
                    var form   = button.closest('.profile-field-form');
                    form.slideUp();
                });
                
                $("input[type='email']").on('keyup',function(){
                    
                    var form = $(this).closest('.profile-field-form');
                    var email1 = $("input[name='user_email']").val();
                    var email2 = $("input[name='user_email2']").val();
                    var alert = form.find('.alert');
                    var validation = form.find("input[name='validation']");
                    
                    if( email1 !== '' && email1 !== email2){
                        validation.val('fail');
                        alert.removeClass('alert-success').addClass('alert-danger').html('Email Mismatch');
                    }else if (email1 !== '' && email1 === email2){
                        validation.val('pass');
                        alert.removeClass('alert-danger').addClass('alert-success').html('Email Match');
                    }
                    
                });
                
                $("input[type='password']").on('keyup',function(){
                    
                    var form = $(this).closest('.profile-field-form');
                    var user_pass1 = $("input[name='user_pass']").val();
                    var user_pass2 = $("input[name='user_pass2']").val();
                    var alert = form.find('.alert');
                    var validation = form.find("input[name='validation']");
                    
                    if( user_pass1 !== '' && user_pass1 !== user_pass2){
                        validation.val('fail');
                        alert.removeClass('alert-success').addClass('alert-danger').html('Password Mismatch');
                    }else if (user_pass1 !== '' && user_pass1 === user_pass2){
                        validation.val('pass');
                        alert.removeClass('alert-danger').addClass('alert-success').html('Password Match');
                    }
                    
                });
                
                
                //create cases
                
                $('#case-location-id,#case-category-id').on('change',function(){
                   var el = $(this); 
                   var location_id = $('#case-location-id').val(); 
                   var category_id = $('#case-category-id').val(); 
                   var service_select = $('#case-service-id');
                   var modal = el.closest('.add-case-page');
                   var loader = $('.btn-loader',modal);

                    $.ajax({
                        type: "POST",
                        datatype: "JSON",
                        url: ajaxurl,
                        data: {
                            'action': 'filter_case_services',
                            'location_id': location_id,
                            'category_id': category_id
                        },
                        beforeSend: function() {
                            loader.css({'display':'inline-block'});
                        },
                        success: function(output) {
                            loader.css({'display':'none'});
                            if (output.status === 200) {
                                service_select.html(output.html);
                            }

                        },
                        error: function() {
                           // alert('A system error...Contact the site admin');
                        }

                    });                        
                   
                });
                
                $('#case-service-id').on('change',function(){
                    
                    var el = $(this);
                    var service_id = el.val();
                    var serviceForm = $('#serviceForm');
                    var loader = $('.btn-loader',el.closest('.add-case-page'));
                    var category_id = $('#case-category-id').val();
                    var alert = $('.case-form-alert');
                    var msg = $('.case-form-msg');  
                    var selected_category_id = el.find(':selected').data('category-id');
                    
                    if(selected_category_id !== category_id && selected_category_id !== undefined){
                        $('#case-category-id').val(selected_category_id);
                    }
                    
                    if(service_id.length === 0 || category_id.length === 0 ){
                        alert.slideUp();
                        msg.slideDown();
                    }else {
                        
                        


                        $.ajax({
                            type: "POST",
                            dataType: "html",
                            url: ajaxurl,
                            data: {
                                'action': 'get_service_form',
                                'service_id': service_id
                            },
                            beforeSend: function() {
                                loader.css({'display':'inline-block'});
                            },
                            success: function(output) {
                                loader.css({'display':'none'});
                                if (output) {
                                    serviceForm.html(output);
                                    $('#serviceFormtab').tab('show');
                                }

                            },
                            error: function() {
                               // alert('A system error...Contact the site admin');
                            }

                        });                        
                     
                    }                    
                    
                    
                });
                
                
                
                fc.selectizeInstance = $('.nelo-selectize').selectize();   
                
                $('#create-case-submit').on('click',function(){
                    
                    var el = $(this);
                    var service_id = $('#case-service-id').val();
                    var category_id = $('#case-category-id').val();
                    var alert = $('.case-form-alert');
                    var msg = $('.case-form-msg');
                    var serviceForm = $('.frm-show-form');
                    var redirect_to = $('#redirect_to').val();
                    
                    if(el.hasClass('disabled')){
                        return false;
                    }
                    
                    if(service_id.length === 0 || category_id.length === 0 ){
                        alert.slideUp();
                        msg.slideDown();
                    }else {
                        
                        $.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {
                                'action': 'create_new_case',
                                'service_id': service_id,
                                'case_details': '',
                                'service_form': serviceForm.serialize()
                            },
                            beforeSend: function() {
                                el.find('.fc-loader').css('opacity',1);
                            },
                            success: function(output) {
                                
                                el.find('.fc-loader').css('opacity',0);
                                if (output.status === 200) {
                                    //location.reload();
                                    window.location.replace(output.url);
                                }
                                
                            },
                            error: function() {
                               // alert('A system error...Contact the site admin');
                            }

                        });                        
                    }
                    
                    
                });

                $('#new-case-modal').on('hidden.bs.modal', function (e) {
                    $('.case-form-alert').show();
                    $('.case-form-msg').hide();
                    $('#case-details').val('');
                    var selectize = fc.selectizeInstance[0].selectize;
                    selectize.setValue("", false);

                });
                
                //close case
                
                $('.close-case-submit').on('click',function(){
                    
                    var el = $(this);
                    var request = el.data('request');
                    var modal = el.closest('.modal');
                    var password = modal.find('.form-password').val();
                    var alert = modal.find('.case-form-alert');
                    var msg = modal.find('.case-form-msg');
                    
                    if(password.length === 0){
                        return false;
                    }else {
                        
                        $.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {
                                'action': 'close_case',
                                'case_id': request.case_id,
                                'password': password
                            },
                            beforeSend: function() {
                                el.find('.fc-loader').css('opacity',1);
                            },
                            success: function(output) {
                                el.find('.fc-loader').css('opacity',0);
                                if (output.status === 401 || output.status === 402 ) {
                                    alert.slideUp();
                                    msg.html(output.msg).slideDown();                                                                        
                                }else if ( output.status === 200 ){
                                    alert.html(output.msg).slideDown();                                                                        
                                    location.reload();
                                }
                                
                            },
                            error: function() {
                               // alert('A system error...Contact the site admin');
                            }

                        });                          
                    }
                    
                    
                });
                
                $('.fce-book-appointment').on('click',function(){
                   var el = $(this);
                   var request = el.data('request');
                   
                        $.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {
                                'action': 'fce_init_case_appointment_booking',
                                'case_id': request.case_id,
                                'service_id': request.service_id
                            },
                            beforeSend: function() {
                                el.find('.fc-loader').css('opacity',1);
                            },
                            success: function(output) {
                                el.find('.fc-loader').css('opacity',0);
                                if (output.status === 200) {
                                    window.location.href = request.url;                                                                  
                                }
                                
                            },
                            error: function() {
                               // alert('A system error...Contact the site admin');
                            }

                        });                    
                        return false;
                });
                
                $('.sce-edit-appointment').on('click',function(e){
                    e.preventDefault();
                    
                    var el = $(this);
                    var appt_id = el.data('appt-id');
                    var url = el.data('url');
                    
                        $.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {
                                'action': 'fce_start_appointment_edit',
                                'appt_id': appt_id,
                            },
                            beforeSend: function() {
                                el.find('.fc-loader').css('opacity',1);
                            },
                            success: function(output) {
                                el.find('.fc-loader').css('opacity',0);
                                if (output.status === 200) {
                                    window.location.href = url;                                                                  
                                }
                                
                            },
                            error: function() {
                               // alert('A system error...Contact the site admin');
                            }

                        });                       
                    
                    
                });
                
                //add accordion events 
                
                $('.panel-collapse').on('show.bs.collapse',function(){
                    var panel = $(this).closest('.panel');
                    $('.panel-heading a',panel).addClass('in');
                    
                });
                
                $('.panel-collapse').on('hide.bs.collapse',function(){
                    var panel = $(this).closest('.panel');
                    $('.panel-heading a',panel).removeClass('in');
                    
                });
                
                //non logged in users 
                
                $('.'+fc.user_not_logged_in).on('click',function(e){
                    e.preventDefault();
                    var modal = $('#modal-login');
                    $('.modal-message',modal).html(fc.login_modal_message);
                    modal.modal();
                });
                
                //logged in users 

                $('.' + fc.user_logged_in).on('click', function (e) {
                    if ($(this).closest('li').hasClass('menu-button')) {
                        e.preventDefault();
                        var modal = $('#new-case-modal');
                        modal.modal();
                    }
                });
                
                $('.book-service'+'.' + fc.user_logged_in).on('click',function(e){
                    e.preventDefault();
                    var modal = $('#new-case-modal');
                    var el = $(this);
                    var service_id = el.data('service-id');
                    var category_id = el.data('category-id');
                    
                    $('#case-category-id').val(category_id);
                    $('#case-service-id').val(service_id).trigger('change');
                    modal.modal();
                    
                });

                
                
                
                //upload documents 


                var file_frame;
                var per_page = 20;

                $('.fce-upload-file').on('click', function(event) {

                    event.preventDefault();
                    
                    var caseID = $(this).data('case-id');
                    var reports_list = $('.case-files',$(this).closest('td'));

                    file_frame = wp.media.frames.file_frame = wp.media({
                        title: fc.file_frame_title,
                        button: {
                            text: fc.file_frame_button_text
                        },
                        library:{
                            type: 'application',
                            posts_per_page: per_page++,
                            post_parent: '0'
                        },
                        multiple: false 
                    });


                    file_frame.on('select', function() {

                        var attachment = file_frame.state().get('selection').first();

                        var form_data = {
                            document_title: attachment.attributes.name,
                            filepath: attachment.attributes.url,
                            document_id: attachment.id,
                            case_id: caseID,
                            action: 'albiruni_submit_document'
                        };

                        jQuery.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: form_data,
                            beforeSend: function() {
                            },
                            success: function(output) {
                                
                                if(output.status === 200){
                                    reports_list.append(output.html);
                                }

                            },
                            error: function() {
                                alert('A system error...Contact the site admin');
                            }

                        });           

                    });


                    file_frame.open();

                });
                
                $('.sce-cancel-appointment').on('click',function(e){
                    e.preventDefault();
                    var el = $(this);
                    var action = el.data('action');
                    var token = el.data('token');
                    $('#appointment-cancel-confirm-submit').attr('data-action',action);
                    $('#appointment-cancel-confirm-submit').attr('data-token',token);
                    $('#cancel-appointment-confirm').modal();
                    
                });
                
                $('#appointment-cancel-confirm-submit').on('click',function(e){
                    e.preventDefault();
                    var el = $(this);
                    var action = el.data('action');
                    var token = el.data('token');
                    var userpass = $('#userpass').val();
                    var msg = $('.cancel-appt-info');
                    
                    if(userpass.length === 0){
                        msg.addClass('alert alert-warning').html('Please be sure to enter your password!');
                    }else {
                        
                        msg.removeClass('alert alert-warning').addClass('alert alert-info').html('Verifying your password ...');
                        
                        jQuery.ajax({
                            type: "POST",
                            datatype: "JSON",
                            url: ajaxurl,
                            data: {action:'authenticate_user',userpass:userpass},
                            beforeSend: function() {
                                el.find('.fc-loader').css('opacity',1);
                            },
                            success: function(output) {
                                
                                if(output.status === 200){
                                    msg.removeClass('alert alert-warning').addClass('alert alert-info').html('Password verified.. Cancelling Appointment...');

                                    jQuery.ajax({
                                        type: "POST",
                                        datatype: "JSON",
                                        url: ajaxurl,
                                        data: {action:action,token:token},
                                        beforeSend: function() {
                                        },
                                        success: function(output) {
                                           location.reload();
                                        },
                                        error: function() {
                                            alert('A system error...Contact the site admin');
                                        }

                                    });                                     
                                    
                                }else {
                                    el.find('.fc-loader').css('opacity',0);
                                    msg.removeClass('alert alert-info').addClass('alert alert-warning').html('Password verification failed!');
                                }

                            },
                            error: function() {
                                alert('A system error...Contact the site admin');
                            }

                        });  

                    }
                     
                    
                    
                });

             $('[data-toggle="tooltip"]').tooltip();

             $('.case-recipients-submit').on('click',function(e){
                 e.preventDefault();
                 var el = $(this);
                 var container = el.parents('tr');
                 var recipients = $('.case-recipients',container).val();
                 var case_id = el.data('case-id');
                 var info = $('.info',container);

                 if(recipients.length > 0){

                     info.removeClass('alert alert-danger').html('');

                     jQuery.ajax({
                         type: "POST",
                         dataType: "JSON",
                         url: ajaxurl,
                         data: {
                             action:'fce_save_case_recipients',
                             recipients:recipients,
                             case_id:case_id
                         },
                         beforeSend: function() {
                             el.find('.fc-loader').css('opacity',1);
                         },
                         success: function(output) {
                             if(output.success){

                                 el.find('.fc-loader').css('opacity',0);

                             }else if(output.success === false && output.valid === false){

                                 el.find('.fc-loader').css('opacity',0);
                                 info.addClass('alert alert-danger').html('There was an error validating one of your emails!');

                             }
                         },
                         error: function() {

                             el.find('.fc-loader').css('opacity',0);
                             alert('A system error...Contact the site admin');

                         }

                     });

                 }else {
                     info.addClass('alert alert-danger').html('Be sure to enter at least one email address!');
                 }

             });
                
                

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
